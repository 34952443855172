section.advantages{
	.row{
		gap: 50px;
		justify-content: center;
		flex-wrap: wrap;
	}
	.advantages__card{
		width: 280px;
		background-color: #1174AF;
		height: 280px;
		border-radius: 26px;
		img{
			border-radius: 26px;
		}
	}
	h3{
		font-size: 22px;
		color: white;
		margin: 20px;
		font-weight: 400;
	}
	h2{
		font-size: 32px;
		margin-bottom: 40px;
		color: white;
		text-align: center;
	}
	button{
		margin: 30px auto 0 auto;
		display: block;
	}
}

@media all and (max-width: 768px) {
	section.advantages{
		.row{
			gap: 20px;
		}
		.advantages__card{
			width: calc(50% - 10px);
			img{
				width: 100%;
				height: 180px;
				object-fit: cover;
				border-radius: 26px;
			}
		}
	}
}

@media all and (max-width: 580px) {
	section.advantages{
		
		.row{
			gap: 10px;
		}

		.advantages__card{
			width: calc(50% - 5px);
			height: 200px;
			border-radius: 15px;
			img{
				width: 100%;
				height: 120px;
				object-fit: cover;
				border-radius: 15px;
			}
		}

		h3{
			font-size: 18px;
			color: white;
			margin: 5px 10px;
			font-weight: 400;
		}
	}
}