.form{
	p{
		color: white;
		font-size: 18px;
		margin-bottom: 20px;
	}
	form{
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	input{
		height: 35px;
		border-radius: 20px;
		border: none;
		padding: 0 15px;
		font-size: 18px;
	}
	.form__message{
		margin: 0;
		text-align: center;
	}
	.red{
		color: red;
	}
	.green{
		color: lightgreen;
	}
}