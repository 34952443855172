section.about{
	color: white;
	h2{
		font-size: 32px;
	}
	img{
		border-radius: 26px;

	}
	.about__descr-text{
		font-size: 18px;
		flex-direction: column;
		gap: 20px;
		line-height: 24px;
		margin: 40px 0;
	}
}

@media all and (max-width: 1300px) {
	section.about{
		.row{
			flex-wrap: wrap;
		}
		.about__descr-text{
			align-items: flex-start;
		}
		.about__images{
			// margin-top: 40px;
			margin: 40px auto 0 auto;
		}
	}
}

@media all and (max-width: 660px) {
	section.about{
		.about__images{
			flex-wrap: nowrap;
			gap: 0;
			justify-content: space-between;
			img{
				width: 32%;
				height: auto;
			}			
		}
	}
}