.fixed-window{
	position: fixed;
	bottom: 10px;
	width: 100%;
	padding: 0 10px;
	left: 0;
	
	.fixed-window__wrapper{
		background: #222222;
		border-radius: 5px;
		margin: 0 auto;
		max-width: 600px;
		padding: 8px;
		justify-content: space-around;
		border: 1px solid #1174AF;
		box-shadow: 1px 1px 4px #1174AF;
		a{
			display: flex;
			align-items: center;
			text-decoration: none;
			color: white;
			font-size: 16px;
			gap: 8px;
		}
	}

	img{
		width: auto;
		height: 28px;
	}
}