@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #222222;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
	max-width: 1300px;
	padding: 0 10px;
	margin: 0 auto;
	width: 100%;
}

.logo {
	font-family: "Russo One", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 20px;
}

.row {
	display: flex;
	align-items: center;
	gap: 20px;
}

.btn {
	height: 35px;
	border-radius: 20px;
	min-width: 240px;
	font-size: 18px;
	color: white;
	background-color: #1174af;
	cursor: pointer;
	border: none;
}

section {
	margin: 80px 0;
}
