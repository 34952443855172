.modal{
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background: #0000009d;
	opacity: 0;
	z-index: -1;
	h2{
		font-size: 32px;
		color: white;
		margin-bottom: 40px;
	}
	.modal__window{
		border-radius: 25px;
		max-width: 380px;
		padding: 20px;
		background-color: #222222;
		position: relative;
		border: 1px solid #1174AF;
		transition: all 0.3s;
		transform: scale(0.1);

		.modal__close{
			position: absolute;
			cursor: pointer;
			right: 15px;
			top: 15px;
			width: 24px;
			height: 24px;
			border: none;
			background: none;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
}

.modal.active{
	opacity: 1;
	z-index: 2;
	.modal__window{
		transform: scale(1);
	}
}