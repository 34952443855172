section.hero{
	margin: 0;
	.hero__image{
		max-width: 640px;
		width: 100%;
		img{
			width: 100%;
			height: auto;
			border-radius: 25px;
		}
	}

	.row{
		gap: 60px;
	}

	h1{
		font-size: 52px;
		color: #1174AF;
		margin-bottom: 20px;
	}

	.hero__descr{
		flex-shrink: 0;
	}

	.hero__subtitle{
		font-size: 24px;
		color: #787878;
	}

	.hero__citation{
		font-size: 32px;
		color: white;
		margin: 20px 0;
	}
	// .btn{
	// 	height: 45px;
	// 	width: 280px;
	// 	font-size: 22px;
	// }
	input{
		max-width: 320px;
		height: 42px !important;
	}
	form button{
		max-width: 320px;
		width: 100% !important;
		height: 42px;
	}
	.form-text{
		display: none;
	}
	.form__message{
		text-align: left !important;
	}
}

@media all and (max-width: 1024px) {
	section.hero{
		.row{
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

@media all and (max-width: 540px) {
	section.hero{
		.row{
			gap: 30px;
		}
		h1{
			font-size: 42px;
		}
	
		.hero__descr{
			flex-shrink: 1;
		}
	
		.hero__subtitle{
			font-size: 22px;
			color: #787878;
		}
	
		.hero__citation{
			font-size: 28px;
			color: white;
			margin: 30px 0;
		}
		// .btn{
		// 	height: 35px;
		// 	width: 280px;
		// 	font-size: 18px;
		// }
		input{
			max-width: 420px;
			height: 42px !important;
		}
		form button{
			max-width: 420px;
			width: 100% !important;
			height: 42px;
		}
	}
}