section.advice{
	h2{
		font-size: 32px;
		margin-bottom: 40px;
		color: white;
	}
	img{
		width: 46px;
		height: 46px;
	}
	.advice__right{
		flex-direction: column;
		align-items: flex-start;
		.row{
			flex-direction: row;
		}
		a, p{
			color: white;
			text-decoration: none;
			font-size: 18px;
		}
	}
	.advice__wrapper{
		gap: 80px;
	}
}

@media all and (max-width: 800px) {
	section.advice{
		h2{
			text-align: center;
		}
		.advice__wrapper{
			flex-wrap: wrap;
			justify-content: center;
			gap: 60px;
		}
	}
}

@media all and (max-width: 460px){
	section.advice{
		.form{
			padding: 0 10px;
			width: 100vw;
		}
	}
}