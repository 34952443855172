footer{
	padding: 15px 0;
	background-color: #1174AF;

	.logo{
		color: white;
		text-decoration: none;
	}
	.row{
		justify-content: space-between;
	}
	.footer__social{
		img{
			width: 42px;
			height: 42px;
		}
	}
}

@media all and (max-width: 768px) {
	footer{
		padding-bottom: 70px;
		.row{
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}