section.feedback{
	h2{
		color: white;
		margin-bottom: 40px;
		font-size: 32px;
	}
	h3{
		font-size: 22px;
		font-weight: 400;
		color: white;
	}
	.feedback__item-user{
		margin-bottom: 20px;
	}
	p{
		font-size: 18px;
		color: white;
	}
	.feedback__wrapper{
		flex-direction: column;
		align-items: flex-start;
		gap: 40px;
	}

	a{
		font-size: 18px;
		color: #ffffff;
		margin-top: 40px;
		display: block;
	}
}

@media all and (max-width: 768px) {
	section.feedback{
		h2{
			text-align: center;
		}
	}
}