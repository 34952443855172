section.prices{
	color: white;
	h2{
		font-size: 32px;
		margin-bottom: 40px;
		text-align: center;
	}
	h3{
		font-size: 22px;
		font-weight: 400;
		margin: 10px 15px;
	}
	span{
		display: block;
		margin: 10px 15px;
	}
	ul{
		color: rgb(239, 239, 239);
		padding-left: 20px;
		margin-top: 20px;
		font-size: 18px;
	}
	.btn{
		background-color: #222222;
		margin: 10px;
		width: calc(100% - 20px);
	}
	.row{
		gap: 40px;
		justify-content: center;
		flex-wrap: wrap;
		align-items: stretch;
	}
	.prices__card{
		width: 290px;
		height: auto;
		border-radius: 26px;
		background-color: #1174AF;
		position: relative;
		display: flex;
		overflow: hidden;
		flex-direction: column;
		justify-content: space-between;
		img{
			width: 100%;
			height: 200px;
			object-fit: cover;
		}
	}
	p{
		margin: 10px 15px;
		font-size: 26px;
	}
}

@media all and (max-width: 1300px) {
	section.prices{
		h2{
			text-align: center;
		}
	}
}