section.map{
	h2{
		margin-bottom: 40px;
		font-size: 32px;
		color: white;
	}
	iframe{
		border: none;
		border-radius: 20px;
	}
}

@media all and (max-width: 768px) {
	section.map{
		h2{
			text-align: center;
		}
	}
}