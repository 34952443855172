header{
	padding: 15px 0 20px 0;
	a{
		font-size: 18px;
		text-decoration: none;
		color: white;
	}
	.row{
		justify-content: space-between;
	}

	nav{
		gap: 35px !important;
	}
}

@media all and (max-width: 860px) {
	header{
		.btn{
			display: none;
		}
	}
}

@media all and (max-width: 540px) {
	header{
		.header__phone p{
			display: none;
		}
	}
}